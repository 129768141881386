// React
import { useEffect, useState, Fragment } from "react"
// Hook Form.
import { useForm } from "react-hook-form"
// Redux.
import { useDispatch, useSelector } from "react-redux"
// DomaSlice.
import {
    domaModalSelector,
    closeModal,
    uploadDoma,
    clearState,
} from "../../features/generale/doma/DomaModalSlice"
// UI.
import Modal from "../../base/Modal"
import DocUploader from "../../base/DocUploader"
import Tooltip from "../../base/Tooltip"
// Icons.
import { OvalLoader, InformationCircle, Check } from "../../base/Icons"

import SearchSelect from '../../base/SearchSelect'
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';

const dayPlaceholder = 'giorno',
    monthPlaceholder = 'mese',
    yearPlaceholder = 'anno';

const daysOptions = (props) => {
    if (!props.numberDays) return
    const options = []
    for (let i = 1; i <= props.numberDays; i++) {
        options.push({ value: i.toString(), label: i.toString() })
    }
    // options.unshift( { value: '', label: dayPlaceholder, disabled: true } )
    return options
}

const monthsOptions = (props) => {
    if (!props.numberMonths) return
    const options = []
    for (let i = 1; i <= props.numberMonths; i++) {
        options.push({ value: i.toString(), label: i.toString() })
    }
    // options.unshift( { value: '', label: monthPlaceholder, disabled: true } )
    return options
}

const yearsOptions = (props) => {
    if (!props.numberYears) return

    const offset = props.offset ? -1 : 1

    const options = []
    for (let i = 0; i <= props.numberYears; i++) {
        options.push({ value: (new Date().getFullYear() + i * offset).toString(), label: (new Date().getFullYear() + i * offset).toString() })
    }
    // options.unshift( { value: '', label: yearPlaceholder, disabled: true } )
    return options
}

/**
 * 
 * @dev DomaModal UI Component.
 *
 * @function DomaModal
 *
 * @returns JSX.Element
 *
 */
export default function DomaModal() {
    const dispatch = useDispatch()

    const [doc, setDoc] = useState("");
    const [isUploaded, setIsUploaded] = useState(false)

    // Boolean State to know if the form has Error.
    //const [formhasErrors,setFormhasErrors] = useState(false);

    /**
     *
     *  Initialize a React hook form State using UseForm Hook.
     *
     * @see https://react-hook-form.com/get-started
     *
     */
    const {
        register,
        handleSubmit,
        setError,
        //clearErrors,
        formState,
        setValue,
        //formState: { errors } // subscription.
    } = useForm({
        mode: "onSubmit",
        reValidateMode: "onChange",
        defaultValues: {
            domaDay: "",
            domaMonth: "",
            domaYear: "",
            scadenzaDomaDay: "",
            scadenzaDomaMonth: "",
            scadenzaDomaYear: "",
            allegato: "",
            formErrors: "",
        },
    }) // UseForm Hook.

    const { isDirty, isValid } = formState // Get the form state.

    // domaModalSlice State.
    const { modalIsFetching, modalIsSuccess, modalIsError, modalErrorMsg } =
        useSelector(domaModalSelector)

    /**
     *
     *  onSubmit Form handler.
     *  Post data to insert a new document.
     */
    // FIXME: Procedure incomplete.
    const onSubmit = (data) => {
        console.log('doc', doc);
        if (!doc) {
            setError("allegato", {
                type: "custom",
                message: "File obbligatorio",
            })
            return
        }

        const domaToUpload = {
            data: `${data.domaYear}-${data.domaMonth}-${data.domaDay}`,
            scadenza: `${data.scadenzaDomaYear}-${data.scadenzaDomaMonth}-${data.scadenzaDomaDay}`,
            allegato: doc,
        }

        if (isValid) {
            dispatch(uploadDoma(domaToUpload))
        }
    }

    const days = daysOptions({ numberDays: 31 })
    const months = monthsOptions({ numberMonths: 12 })
    const [years, setYears] = useState(yearsOptions({ numberYears: 100, offset: true }));
    const yearsAfter = yearsOptions({ numberYears: 100, offset: false })

    const domaDay = register('domaDay', { required: true })
    const domaMonth = register('domaMonth', { required: true })
    const domaYear = register('domaYear', { required: true })

    const scadenzaDomaDay = register('scadenzaDomaDay', { required: true })
    const scadenzaDomaMonth = register('scadenzaDomaMonth', { required: true })
    const scadenzaDomaYear = register('scadenzaDomaYear', { required: true })

    /**
     *
     * Component side effects.
     *
     */
    useEffect(() => {
        if (modalIsSuccess) {
            dispatch(closeModal()) // Close Modal.

            dispatch(clearState()) // Clean up the UserSlice State.
        }
    }, [dispatch, modalIsFetching, modalIsSuccess, modalIsError, modalErrorMsg, formState])

    /**
     * DOC handler
     */
    const accept = 'image/*,.pdf';

    const Uploader = (props) => {
        const name = 'allegato';

        function onChangeHandler(event) {
            const file = event.target.files[0]
            const fileURL = URL.createObjectURL(file) // Create a blob url with uploaded image.

            if (file) {
                setDoc(file)
                setIsUploaded(true)
            }

            if (props.onChange && file) {
                props.onChange(file)
            }
        }

        return (
            <div className="inline-flex align-middle opacity-100 text-xs">
                <div
                    className="
                            relative
                            w-32
                            overflow-hidden
                            outline outline-1
                            my-4
                            p-2
                            text-black"
                >
                    <input
                        type="file"
                        name={name}
                        accept={accept}
                        {...register("allegato", {
                            // required: true
                            // required: "Allegato Obbligatorio",
                        })}
                        onChange={(e) => {
                            onChangeHandler(e)
                        }}
                        className="absolute top-0 right-0 cursor-pointer opacity-0 w-full h-full"
                    />

                    <p className="text-base">Scegli file...</p>
                </div>

                <div className="flex items-center justify-center ml-10">
                    {isUploaded ? (
                        <Fragment>
                            <p className="text-base">File caricato con successo</p>
                            <span
                                className="
                                        ml-2
                                        rounded-full
                                        h-6
                                        w-6
                                        bg-afm-box-success-green
                                        text-white"
                            >
                                <Check />
                            </span>
                        </Fragment>
                    ) : (
                        <p className="text-base">Nessun file caricato</p>
                    )}
                </div>
            </div>
        )
    }

    /**
     *
     * Component render.
     *
     */
    return (
        <Modal title="Nuovo Doma">
            <form action="" className="p-4" onSubmit={handleSubmit(onSubmit)} encType={'multipart/form-data'} method="POST">
                <div className="flex gap-3">
                    <div
                        className="
                            flex flex-row 
                            text-left 
                            items-center justify-center 
                            mt-6 mb-2"
                    >
                        <label
                            htmlFor="domaDay"
                            className="
                                mb-2 
                                pr-2 
                                pt-1 
                                text-left text-base text-afm-box-dark-gray"
                        >
                            Data Doma<top className="text-red-500 ml-1">*</top>
                        </label>
                    </div>
                </div>

                <div className="inline-flex space-x-4 w-full">
                    <Select
                        {...domaDay}
                        options={days || []}
                        onChange={(selected) => {
                            setValue("domaDay", selected?.value || '', { shouldDirty: true, shouldValidate: true });
                        }}
                        placeholder={dayPlaceholder}
                        className="relative w-full"
                        name="domaDay"
                    />
                    <Select
                        {...domaMonth}
                        options={months || []}
                        onChange={(selected) => {
                            setValue("domaMonth", selected?.value || '', { shouldDirty: true, shouldValidate: true });
                        }}
                        placeholder={monthPlaceholder}
                        className="relative w-full"
                        name="domaMonth"
                    />
                    <CreatableSelect
                        {...domaYear}
                        options={years || []}
                        formatCreateLabel={(inputValue) => inputValue}
                        onChange={(selected) => {
                            setValue("domaYear", selected?.value || '', { shouldDirty: true, shouldValidate: true });
                            if(!selected?.value){
                                return
                            };
                            const newOption = {
                                value: selected?.value,
                                label: selected?.label
                            };
                            if (years.findIndex(x => x.value == selected.value) == -1) {
                                setYears([...years, newOption]);
                            }
                        }}
                        placeholder={yearPlaceholder}
                        className="relative w-full"
                        name="domaYear"
                    />
                </div>

                <div className="flex gap-3">
                    <div
                        className="
                            flex flex-row 
                            text-left items-center justify-center 
                            mt-6 mb-2"
                    >
                        <label
                            htmlFor="domaDay"
                            className="
                                mb-2 
                                pr-2 
                                pt-1 
                                text-left text-base text-afm-box-dark-gray"
                        >
                            Data Scadenza<top className="text-red-500 ml-1">*</top>
                        </label>

                        <span
                            className="
                                h-6 w-6 
                                rounded-full 
                                bg-afm-box-light-gray 
                                text-afm-box-dark-gray"
                        >
                            <Tooltip
                                message={"La Dichiarazione dell’organico medio annuo scade il 31 dicembre di ogni anno."}
                                twBackground="bg-afm-box-blue"
                                translateTooltip={true}
                            >
                                <InformationCircle />
                            </Tooltip>
                        </span>
                    </div>
                </div>
                <div className="inline-flex space-x-4 w-full">
                    <Select
                        {...scadenzaDomaDay}
                        options={days || []}
                        onChange={(selected) => {
                            setValue("scadenzaDomaDay", selected?.value || '', { shouldDirty: true, shouldValidate: true });
                        }}
                        placeholder={dayPlaceholder}
                        className="relative w-full"
                        name="scadenzaDomaDay"
                    />
                    <Select
                        {...scadenzaDomaMonth}
                        options={months || []}
                        onChange={(selected) => {
                            setValue("scadenzaDomaMonth", selected?.value || '', { shouldDirty: true, shouldValidate: true });
                        }}
                        placeholder={monthPlaceholder}
                        className="relative w-full"
                        name="scadenzaDomaMonth"
                    />
                    <Select
                        {...scadenzaDomaYear}
                        options={yearsAfter || []}
                        onChange={(selected) => {
                            setValue("scadenzaDomaYear", selected?.value || '', { shouldDirty: true, shouldValidate: true });
                        }}
                        placeholder={yearPlaceholder}
                        className="relative w-full"
                        name="scadenzaDomaYear"
                    />
                </div>

                <div className="flex-col text-left mt-6 mb-2">
                    <label>Upload Doma<top className="text-red-500 ml-1">*</top></label>
                </div>
                <div className="block">
                    <Uploader />
                </div>

                <div className="flex mt-10">
                    <div className="flex-1">
                        <button
                            type="button"
                            className="
                                flex 
                                gap-1 
                                w-auto 
                                px-7 
                                h-10 
                                items-center justify-center
                                uppercase whitespace-nowrap font-semibold 
                                text-white 
                                bg-afm-box-dark-gray
                                hover:opacity-80 
                                focus:outline-none"
                            onClick={() => {
                                dispatch(closeModal())
                            }}
                        >
                            annulla
                        </button>
                    </div>
                    <button
                        type="submit"
                        value="Submit"
                        disabled={!isDirty || !isValid}
                        className="
                            flex 
                            w-auto 
                            px-7 
                            h-10 
                            gap-1 
                            items-center justify-center 
                            uppercase whitespace-nowrap font-semibold 
                            text-white 
                            bg-afm-box-blue 
                            hover:opacity-80 
                            focus:outline-none"
                    >
                        {modalIsFetching && <OvalLoader />} procedi
                    </button>
                </div>

                {modalIsError && (
                    <div
                        className="
                            h-8 
                            inline-flex 
                            items-center justify-items-center 
                            mt-2 
                            px-20 
                            bg-afm-box-error-red/10 
                            text-afm-box-error-red 
                            border border-afm-box-error-red rounded-md"
                    >
                        <p>{modalErrorMsg}</p>
                    </div>
                )}
            </form>
        </Modal>
    )
}
