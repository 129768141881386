import { useEffect, useState, Fragment } from "react"
// Hook Form.
import { useForm } from "react-hook-form"
// Redux.
import { useDispatch, useSelector } from "react-redux"
// VisuraModalSlice.
import {
    durcModalSelector,
    closeModal,
    uploadDURC,
    clearState,
} from "../../features/generale/durc/DurcModalSlice"
// UI.
import Modal from "../../base/Modal"
// import DocUploader from "../../base/DocUploader"
import Tooltip from "../../base/Tooltip"
// Icons.
import { OvalLoader, InformationCircle, Check } from "../../base/Icons"

// import SearchSelect from '../../base/SearchSelect'
import Select from 'react-select'
import CreatableSelect from 'react-select/creatable';

const dayPlaceholder = 'giorno',
    monthPlaceholder = 'mese',
    yearPlaceholder = 'anno';

const daysOptions = (props) => {
    if (!props.numberDays) return
    const options = []
    for (let i = 1; i <= props.numberDays; i++) {
        options.push({ value: i.toString(), label: i.toString() })
    }
    // options.unshift({ value: '', label: dayPlaceholder, disabled: false })
    return options
}

const monthsOptions = (props) => {
    if (!props.numberMonths) return
    const options = []
    for (let i = 1; i <= props.numberMonths; i++) {
        options.push({ value: i.toString(), label: i.toString() })
    }
    // options.unshift({ value: '', label: monthPlaceholder, disabled: true })
    return options
}

const yearsOptions = (props) => {
    if (!props.numberYears) return

    const offset = props.offset ? -1 : 1

    const options = []
    for (let i = 0; i <= props.numberYears; i++) {
        options.push({ value: (new Date().getFullYear() + i * offset).toString(), label: (new Date().getFullYear() + i * offset).toString() })
    }
    // options.unshift({ value: '', label: yearPlaceholder, disabled: true })
    return options
}

/**
 *
 * @dev DurcModal Component.
 * 
 * @function VisuraModal
 * 
 * @returns JSX.Element
 *
 */
export default function DurcModal() {
    const dispatch = useDispatch()

    const [doc, setDoc] = useState("");
    const [isUploaded, setIsUploaded] = useState(false)

    /**
     *
     *  Initialize a React hook form State using UseForm Hook.
     *
     * @see https://react-hook-form.com/get-started
     *
     */
    const {
        register,
        handleSubmit,
        setError,
        //clearErrors,
        formState,
        setValue,
        //formState: { errors } // subscription.
    } = useForm({
        mode: "onSubmit",
        reValidateMode: "onChange",
        defaultValues: {
            docDay: "",
            docMonth: "",
            docYear: "",
            scadenzaDocDay: "",
            scadenzaDocMonth: "",
            scadenzaDocYear: "",
            allegato: "",
        },
    }) // UseForm Hook.

    const { isDirty, isValid } = formState // Get the form state.

    // visuraModalSlice State.
    const { modalIsFetching, modalIsSuccess, modalIsError, modalErrorMsg } =
        useSelector(durcModalSelector)

    /**
     * 
     * @dev onSubmit Form handler.
     * @dev Post data to insert a new document.
     * 
     * @const onSubmit
     * @param { * } data
     * @returns void
     * 
     */
    // FIXME: Procedure incomplete.
    const onSubmit = (data) => {
        console.log('onSubmit', doc);
        if (!doc) {
            setError("allegato", {
                type: "custom",
                message: "Immagine Macchina obbligatoria",
            })
            return
        }

        if (data.docDay === dayPlaceholder || data.scadenzaDocDay === dayPlaceholder) {
            setError("docDay", {
                type: "custom",
                message: "Giorno obbligatorio",
            })

            return
        }

        if (data.docMonth === monthPlaceholder || data.scadenzaDocMonth === monthPlaceholder) {
            setError("docMonth", {
                type: "custom",
                message: "Mese obbligatorio",
            })

            return
        }

        if (data.docYear === yearPlaceholder || data.scadenzaDocYear === yearPlaceholder) {
            setError("docYear", {
                type: "custom",
                message: "Anno obbligatorio",
            })

            return
        }

        const docToUpload = {
            data: `${data.docYear}-${data.docMonth}-${data.docDay}`,
            scadenza: `${data.scadenzaDocYear}-${data.scadenzaDocMonth}-${data.scadenzaDocDay}`,
            allegato: doc,
        }

        if (isValid) {
            dispatch(uploadDURC(docToUpload))
        }
    }

    /**
     *
     * Component side effects.
     *
     */
    useEffect(() => {
        if (modalIsSuccess) {
            dispatch(closeModal()) // Close Modal.
            dispatch(clearState()) // Clean up the UserSlice State.
        }

    }, [
        dispatch,
        modalIsFetching,
        modalIsSuccess,
        modalIsError,
        modalErrorMsg,
        formState
    ])

    const days = daysOptions({ numberDays: 31 })
    const months = monthsOptions({ numberMonths: 12 })
    const [years, setYears] = useState(yearsOptions({ numberYears: 100, offset: true }));
    const yearsAfter = yearsOptions({ numberYears: 100, offset: false })

    const docDay = register('docDay', { required: true })
    const docMonth = register('docMonth', { required: true })
    const docYear = register('docYear', { required: true })

    const scadenzaDocDay = register('scadenzaDocDay', { required: true })
    const scadenzaDocMonth = register('scadenzaDocMonth', { required: true })
    const scadenzaDocYear = register('scadenzaDocYear', { required: true })

    // const checkLeapYears = (data) => {
    //     console.log(data); // LOGS DATA FROM CHILD COMPONENT.
    // }

    /**
     * DOC handler
     */
    const accept = 'image/*,.pdf';

    const Uploader = (props) => {
        const name = 'allegato';

        function onChangeHandler(event) {
            console.log('onChangeHandler', event);
            const file = event.target.files[0]
            const fileURL = URL.createObjectURL(file) // Create a blob url with uploaded image.

            if (file) {
                setDoc(file)
                setIsUploaded(true)
            }

            if (props.onChange && file) {
                props.onChange(file)
            }
        }

        return (
            <div className="inline-flex align-middle opacity-100 text-xs">
                <div
                    className="
                            relative 
                            w-32 
                            overflow-hidden 
                            outline outline-1 
                            my-4 
                            p-2 
                            text-black"
                >
                    <input
                        type="file"
                        name={name}
                        accept={accept}
                        {...register("allegato", {
                            // required: "Allegato Obbligatorio",
                        })}
                        onChange={(e) => { onChangeHandler(e) }}
                        className="absolute top-0 right-0 cursor-pointer opacity-0 w-full h-full"
                    />

                    <p className="text-base">Scegli file...</p>
                </div>

                <div className="flex items-center justify-center ml-10">
                    {isUploaded ? (
                        <Fragment>
                            <p className="text-base">File caricato con successo</p>
                            <span
                                className="
                                        ml-2 
                                        rounded-full 
                                        h-6 
                                        w-6 
                                        bg-afm-box-success-green 
                                        text-white"
                            >
                                <Check />
                            </span>
                        </Fragment>
                    ) : (
                        <p className="text-base">Nessun file caricato</p>
                    )}
                </div>
            </div>
        )
    }

    /**
     *
     * Component render.
     *
     */
    return (
        <Modal title="Nuovo DURC">
            <form
                action=""
                className="p-4"
                onSubmit={handleSubmit(onSubmit)}
                encType={'multipart/form-data'}
                method="POST"
            >
                <div className="flex gap-3">
                    <div
                        className="
                            flex 
                            flex-row 
                            text-left 
                            items-center justify-center 
                            mt-6 
                            mb-2"
                    >

                        <label
                            htmlFor="docDay"
                            className="
                                mb-2 
                                pl-2 
                                pt-1 
                                text-left 
                                text-base 
                                text-afm-box-dark-gray"
                        >
                            Data DURC<top className="text-red-500 ml-1">*</top>
                        </label>
                        <span
                            className="
                                h-6 
                                w-6 
                                ml-2
                                mb-1
                                rounded-full 
                                text-afm-box-dark-gray"
                        >

                        </span>
                    </div>
                </div>

                <div className="inline-flex space-x-4 w-full">
                    <Select
                        {...register("docDay", { required: true })}
                        options={days || []}
                        onChange={(selected) => {
                            setValue("docDay", selected?.value || '', { shouldDirty: true, shouldValidate: true });
                        }}
                        placeholder={dayPlaceholder}
                        className="relative w-full"
                        name="docDay"
                    />
                    <Select
                        {...register("docMonth", { required: true })}
                        options={months || []}
                        onChange={(selected) => {
                            setValue("docMonth", selected?.value || '', { shouldDirty: true, shouldValidate: true });
                        }}
                        placeholder={monthPlaceholder}
                        className="relative w-full"
                        name="docMonth"
                    />
                    <CreatableSelect
                        {...register("docYear", { required: true })}
                        options={years || []}
                        formatCreateLabel={(inputValue) => inputValue}
                        onChange={(selected) => {
                            setValue("docYear", selected?.value || '', { shouldDirty: true, shouldValidate: true });
                            if(!selected?.value){
                                return
                            };
                            const newOption = {
                                value: selected?.value,
                                label: selected?.label
                            };
                            if (years.findIndex(x => x.value == selected.value) == -1) {
                                setYears([...years, newOption]);
                            }
                        }}
                        placeholder={yearPlaceholder}
                        className="relative w-full"
                        name="docYear"
                    />
                </div>

                <div className="flex gap-3">
                    <div
                        className="
                            flex flex-row 
                            text-left 
                            items-center 
                            justify-center 
                            mt-6 
                            mb-2"
                    >
                        <label
                            htmlFor="docDay"
                            className="
                                mb-2 
                                pl-2 
                                pt-1 
                                text-left text-base text-afm-box-dark-gray"
                        >
                            Data Scadenza<top className="text-red-500 ml-1">*</top>
                        </label>

                        <span
                            className="
                                h-6 
                                w-6 
                                ml-2
                                mb-1
                                rounded-full 
                                text-afm-box-dark-gray"
                        >
                            <Tooltip
                                message={"Il DURC ha una durata di 3 mesi."}
                                twBackground="bg-afm-box-blue"
                            // translateTooltip={true}
                            >
                                <InformationCircle />
                            </Tooltip>
                        </span>
                    </div>
                </div>
                <div className="inline-flex space-x-4 w-full">
                    <Select
                        {...register("scadenzaDocDay", { required: true })}
                        options={days || []}
                        onChange={(selected) => {
                            setValue("scadenzaDocDay", selected?.value || '', { shouldDirty: true, shouldValidate: true });
                        }}
                        placeholder={dayPlaceholder}
                        className="relative w-full"
                        name="scadenzaDocDay"
                    />
                    <Select
                        {...register("scadenzaDocMonth", { required: true })}
                        options={months || []}
                        onChange={(selected) => {
                            setValue("scadenzaDocMonth", selected?.value || '', { shouldDirty: true, shouldValidate: true });
                        }}
                        placeholder={monthPlaceholder}
                        className="relative w-full"
                        name="scadenzaDocMonth"
                    />
                    <Select
                        {...register("scadenzaDocYear", { required: true })}
                        options={yearsAfter || []}
                        onChange={(selected) => {
                            setValue("scadenzaDocYear", selected?.value || '', { shouldDirty: true, shouldValidate: true });
                        }}
                        placeholder={yearPlaceholder}
                        className="relative w-full"
                        name="scadenzaDocYear"
                    />
                </div>

                <div className="flex-col text-left mt-6 mb-2">
                    <label>Upload DURC<top className="text-red-500 ml-1">*</top></label>
                </div>
                <div className="block">
                    <Uploader />
                </div>

                <div className="flex mt-10">
                    <div className="flex-1">
                        <button
                            type="button"
                            className="
                                flex 
                                w-auto 
                                px-7 
                                h-10 
                                gap-1 
                                items-center justify-center 
                                uppercase whitespace-nowrap font-semibold 
                                text-white 
                                bg-afm-box-dark-gray 
                                hover:opacity-80 
                                focus:outline-none"
                            onClick={() => {
                                dispatch(closeModal())
                            }}
                        >
                            annulla
                        </button>
                    </div>

                    <button
                        type="submit"
                        value="Submit"
                        // disabled={!isDirty || !isValid}
                        className="
                            disabled:opacity-50 
                            flex 
                            w-auto 
                            px-7 
                            h-10 
                            gap-1 
                            items-center justify-center 
                            uppercase whitespace-nowrap font-semibold 
                            text-white 
                            bg-afm-box-blue 
                            hover:opacity-80 
                            focus:outline-none"
                    >
                        {modalIsFetching && <OvalLoader />} procedi
                    </button>
                </div>

                {modalIsError && (
                    <div
                        className="
                            h-8 
                            inline-flex 
                            items-center 
                            justify-items-center 
                            mt-2 
                            px-20 
                            bg-afm-box-error-red/10 
                            text-afm-box-error-red 
                            border border-afm-box-error-red rounded-md"
                    >
                        <p>{modalErrorMsg}</p>
                    </div>
                )}
            </form>
        </Modal>
    )
}
