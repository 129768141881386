/**
 *
 * @notice PrivacySlice to handle state of Insediamenti globally.
 *
 * @dev This module contains the reducer configuration and its initialization.
 * @dev Is the sucker wich contains features of this slice.
 *
 */
// Redux.
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
// Axios.
import axios from "axios"
// gmapi.
import { API_KEY, downloadPrivacyDocAPI, listaPrivacyAPI } from "../../../common/gmApi"
// cookie.
import { tkbox, getCookie } from "../../../common/cookie"
// util.
import { downloadDocument, showDocument } from "../../../common/util"

/********************************************
 *                                          *
 * @notice AsyncThunk API(s) Implementation *
 *                                          *
 ********************************************/
/**
 *
 *  @dev Get privacy data.
 *
 */
export const getPrivacyDocs = createAsyncThunk(
    // Action route.
    "privacy/getPrivacyDocs",
    /**
     * 
     * @dev Async Function with axios http req.
     * 
     * @param { AsyncThunkPayloadCreator } thunkAPI
     * @dev `thunkAPI` callback function that should return a promise
     * @dev handle with extraReducers to update the State.
     * @dev It takes two parameter too: first is the value of the dispatched action,
     * @dev and the second is the Thunk API config.
     * 
     * @returns AsyncThunk
     * 
     */
    async (thunkAPI) => {
        try {
            const response = await axios.get(
                // url.
                listaPrivacyAPI,
                // body.
                // null,
                // Queryparams.
                {
                    params: {
                        apikey: API_KEY,
                        tkbox: getCookie(tkbox),
                    },
                }
            )
            if (response.status === 200 && response.data.gmapi.auth === "ok") {
                if (response.data.gmapi.response.status === "ok") {
                    return response.data
                } else {
                    //console.error('Error "getPrivacyDocs":',response.data.gmapi.response.error[0])
                    return thunkAPI.rejectWithValue(response.data.gmapi.response.error[0])
                }
            } else {
                //console.error('Error "getPrivacyDocs":',response.data.gmapi.error)
                return thunkAPI.rejectWithValue(response.data.gmapi.error)
            }
        } catch (error) {
            console.error('Error "getPrivacyDocs":', error.message)
            return thunkAPI.rejectWithValue(error.message)
        }
    }
)

/**
 *
 *  @dev Download privacy doc by id.
 *
 */
export const downloadPrivacyDocById = createAsyncThunk(
    // Action route.
    "privacy/downloadPrivacyDocById",
    /**
     * 
     * @dev Async Function with axios http req.
     * 
     * @param { string } idBoxPrivacy
     * 
     * @param { AsyncThunkPayloadCreator } thunkAPI
     * @dev `thunkAPI` callback function that should return a promise
     * @dev handle with extraReducers to update the State.
     * @dev It takes two parameter too: first is the value of the dispatched action,
     * @dev and the second is the Thunk API config.
     * 
     * @returns AsyncThunk
     * 
     */
    async (idBoxPrivacy, thunkAPI) => {
        try {
            const response = await axios.get(
                // url.
                downloadPrivacyDocAPI,
                // body.
                //null,
                // Queryparams.
                {
                    params: {
                        apikey: API_KEY,
                        tkbox: getCookie(tkbox),
                        id_box_privacy: idBoxPrivacy,
                    },
                }
            )
            if (response.status === 200 && response.data.gmapi.auth === "ok") {
                if (response.data.gmapi.response.status === "ok") {
                    // Build a custom response with extradata.
                    return response
                } else {
                    //console.error('Error "downloadPrivacyDocById":',response.data.gmapi.response.error[0])
                    return thunkAPI.rejectWithValue(response.data.gmapi.response.error[0])
                }
            } else {
                //console.error('Error "downloadPrivacyDocById":',response.data.gmapi.error)
                return thunkAPI.rejectWithValue(response.data.gmapi.error)
            }
        } catch (error) {
            console.error('Error "downloadPrivacyDocById":', error.message)
            return thunkAPI.rejectWithValue(error.message)
        }
    }
)

/**
 *
 *  @dev Show privacy doc by id.
 *
 */
export const showPrivacyDocById = createAsyncThunk(
    // Action route.
    'privacy/showPrivacyDocById',
    /**
     * 
     * @dev Async Function with axios http req.
     * 
     * @param { string } idBoxPrivacy
     * 
     * @param { AsyncThunkPayloadCreator } thunkAPI
     * @dev `thunkAPI` callback function that should return a promise
     * @dev handle with extraReducers to update the State.
     * @dev It takes two parameter too: first is the value of the dispatched action,
     * @dev and the second is the Thunk API config.
     * 
     * @returns AsyncThunk
     * 
     */
    async (idBoxPrivacy, thunkAPI) => {
        try {

            const response = await axios.get(
                // url.
                downloadPrivacyDocAPI,
                // body.
                // null,
                // Queryparams.
                {
                    params: {
                        apikey: API_KEY,
                        tkbox: getCookie(tkbox),
                        id_box_privacy: idBoxPrivacy
                    }
                }
            )
            if (response.status === 200 && response.data.gmapi.auth === "ok") {
                if (response.data.gmapi.response.status === 'ok') {
                    // Build a custom response with extradata.
                    return response
                } else {
                    //console.error('Error "showPrivacyDocById":',response.data.gmapi.response.error[0])
                    return thunkAPI.rejectWithValue(response.data.gmapi.response.error[0]);
                };
            } else {
                //console.error('Error "showPrivacyDocById":',response.data.gmapi.error)
                return thunkAPI.rejectWithValue(response.data.gmapi.error)
            }
        } catch (error) {
            console.error('Error "showPrivacyDocById":', error.message);
            return thunkAPI.rejectWithValue(error.message);
        }
    }
)

/***************************************
 *                                     *
 * @notice createSlice implementation. *
 *                                     *
 * @dev Create "privacySlice" feature. *
 * @dev UI views state controller.     *
 *                                     *
 ***************************************/
export const privacySlice = createSlice({
    // Name, used in action types.
    name: "privacy",
    // The initial state for the reducer.
    initialState: {
        privacyDocsItems: [],         // All privacy Docs.
        // Docs.
        privacyDocs: [],              // Privacy Docs.
        requiredDocs: [],             // All `Documenti obbligatori` privacy Docs.
        otherDocs: [],                // All `Altri Documenti` privacy Docs.
        appointInternalStaffDocs: [], // All `Nomina addetti interni Trattamento Dati` privacy Docs.
        externalManagersDocs: [],     // All `Responsabili esterni Trattamento Dati` privacy Docs.
        // Mods.
        privacyMods: [],              // Privacy Mods.
        requiredMods: [],             // All `Documenti obbligatori` privacy Mods.
        otherMods: [],                // All `Altri Documenti` privacy Mods.
        appointInternalStaffMods: [], // All `Nomina addetti interni Trattamento Dati` privacy Mods.
        externalManagersMods: [],     // All `Responsabili esterni Trattamento Dati` privacy Mods.
        //
        isFetching: false,            // AsyncThunk is calling an API.
        isSuccess: false,             // AsyncThunk is success from API call.
        isError: false,               // AsyncThunk is fail from API call.
        errorMsg: "",                 // Error message container.
        //
        privacyDoc: [],               // Privacy Doc.
        privacyDocName: "",           // Privacy Doc name.
        privacyDocSenderId: "",       // Privacy Doc sender id.
        isFetchingDoc: false,         // AsyncThunk is calling an API.
        isSuccessDoc: false,          // AsyncThunk is success from API call.
        isErrorDoc: false,            // AsyncThunk is fail from API call.
        errorMsgDoc: "",              // Error message container.
    },
    // Reducers, an object of "case reducers".
    // Key names will be used to generate actions.
    reducers: {
        /**
         * @action clearPrivacyDocSenderId
         */
        clearPrivacyDocSenderId: (state) => {
            state.privacyDocSenderId = ""

            return state
        },
    },
    // AsyncThunk Reducers.
    // A "builder callback" function used to add more reducers, or
    // an additional object of "case reducers", where the keys should be other
    // action types.
    extraReducers: (builder) => {
        builder
            /*******************************************************************
             *
             * @dev getPrivacyDocs.
             * 
             */
            .addCase(getPrivacyDocs.fulfilled, (state, action) => {
                state.isFetching = false
                state.isSuccess = true

                // Fill the state with all items.
                state.privacyDocsItems = action.payload.gmapi.response.data

                let privacyDocs = []
                let requiredDocs = []
                let otherDocs = []
                let appointInternalStaffDocs = []
                let externalManagersDocs = []

                let privacyMods = []
                let requiredMods = []
                let otherMods = []
                let appointInternalStaffMods = []
                let externalManagersMods = []


                for (let index = 0; index < state.privacyDocsItems.length; ++index) {
                    const element = state.privacyDocsItems[index]
                    const normalizedTipologia = (element.tipologia) ? element.tipologia.trim().toLowerCase() : '';
                    const normalizedTipo = (element.tipo) ? element.tipo.trim().toLowerCase() : '';

                    switch (normalizedTipologia) {
                        case "documento privacy":
                            // no break
                        case "documento":
                            privacyDocs.push(element)
                            switch (normalizedTipo) {
                                case "documenti obbligatori":
                                    requiredDocs.push(element)
                                    break

                                case "altri documenti":
                                    otherDocs.push(element)
                                    break

                                case "nomina addetti interni trattamento dati":
                                    // no break
                                case "nomina addetti interni trattamento dati":
                                    appointInternalStaffDocs.push(element)
                                    break

                                case "responsabili esterni trattamenti dati":
                                    externalManagersDocs.push(element)
                                    break

                                default:
                                    break
                            }
                            break
                        
                        case "documento master privacy":
                            // no break
                        case "modello":

                            privacyMods.push(element)
                            otherMods.push(element)

                            // switch (normalizedTipo) {
                            //     case "documenti obbligatori":
                            //         requiredMods.push(element)
                            //         break

                            //     case "altri documenti":
                            //         otherMods.push(element)
                            //         break

                            //     case "nomina addetti interni trattamento dati":
                            //         appointInternalStaffMods.push(element)
                            //         break

                            //     case "responsabili esterni trattamenti dati":
                            //         externalManagersMods.push(element)
                            //         break

                            //     default:
                            //         break
                            // }
                            // break

                        default:
                            break
                    }
                }

                //
                // Privacy Docs.
                //
                if (privacyDocs.length > 0) {
                    //
                    // Update privacyDocs sort by `data_ora_inserimento` key.
                    state.privacyDocs = privacyDocs.sort((firstEl, secondEl) => (
                        firstEl["data_ora_inserimento"] < secondEl["data_ora_inserimento"] ? 1 : -1
                    ))
                }
                if (requiredDocs.length > 0) {
                    //
                    // Update requiredDocs sort by `data_ora_inserimento` key.
                    state.requiredDocs = requiredDocs.sort((firstEl, secondEl) => (
                        firstEl["data_ora_inserimento"] < secondEl["data_ora_inserimento"] ? 1 : -1
                    ))
                }
                if (otherDocs.length > 0) {
                    //
                    // Update otherDocs sort by `data_ora_inserimento` key.
                    state.otherDocs = otherDocs.sort((firstEl, secondEl) => (
                        firstEl["data_ora_inserimento"] < secondEl["data_ora_inserimento"] ? 1 : -1
                    ))
                }
                if (appointInternalStaffDocs.length > 0) {
                    //
                    // Update appointInternalStaffDocs sort by `data_ora_inserimento` key.
                    state.appointInternalStaffDocs = appointInternalStaffDocs.sort((firstEl, secondEl) => (
                        firstEl["data_ora_inserimento"] < secondEl["data_ora_inserimento"] ? 1 : -1
                    ))
                }
                if (externalManagersDocs.length > 0) {
                    //
                    // Update externalManagersDocs sort by `data_ora_inserimento` key.
                    state.externalManagersDocs = externalManagersDocs.sort((firstEl, secondEl) => (
                        firstEl["data_ora_inserimento"] < secondEl["data_ora_inserimento"] ? 1 : -1
                    ))
                }

                //
                // Privacy Mods.
                //
                if (privacyMods.length > 0) {
                    //
                    // Update privacyMods sort by `data_ora_inserimento` key.
                    state.privacyMods = privacyMods.sort((firstEl, secondEl) => (
                        firstEl["data_ora_inserimento"] < secondEl["data_ora_inserimento"] ? 1 : -1
                    ))
                }
                if (requiredMods.length > 0) {
                    //
                    // Update requiredMods sort by `data_ora_inserimento` key.
                    state.requiredMods = requiredMods.sort((firstEl, secondEl) => (
                        firstEl["data_ora_inserimento"] < secondEl["data_ora_inserimento"] ? 1 : -1
                    ))
                }
                if (otherMods.length > 0) {
                    //
                    // Update otherMods sort by `data_ora_inserimento` key.
                    state.otherMods = otherMods.sort((firstEl, secondEl) => (
                        firstEl["data_ora_inserimento"] < secondEl["data_ora_inserimento"] ? 1 : -1
                    ))
                }
                if (appointInternalStaffMods.length > 0) {
                    //
                    // Update appointInternalStaffMods sort by `data_ora_inserimento` key.
                    state.appointInternalStaffMods = appointInternalStaffMods.sort((firstEl, secondEl) => (
                        firstEl["data_ora_inserimento"] < secondEl["data_ora_inserimento"] ? 1 : -1
                    ))
                }
                if (externalManagersMods.length > 0) {
                    //
                    // Update externalManagersMods sort by `data_ora_inserimento` key.
                    state.externalManagersMods = externalManagersMods.sort((firstEl, secondEl) => (
                        firstEl["data_ora_inserimento"] < secondEl["data_ora_inserimento"] ? 1 : -1
                    ))
                }

            })
            .addCase(getPrivacyDocs.pending, (state) => {
                state.isFetching = true
            })
            .addCase(getPrivacyDocs.rejected, (state, action) => {
                state.isFetching = false
                state.isError = true
                state.errorMsg = action.payload
            })
            /*******************************************************************
             *
             * @dev downloadPrivacyDocById.
             * 
             */
            .addCase(downloadPrivacyDocById.fulfilled, (state, action) => {
                state.isFetchingDoc = false
                state.isSuccessDoc = true

                // Update the state with the file name from action payload.
                state.privacyDoc = action.payload.data.gmapi.response.data;

                for (let index = 0; index < state.privacyDocsItems.length; index++) {
                    const element = state.privacyDocsItems[index]
                    if (element.id_box_privacy === state.privacyDocSenderId) {
                        state.privacyDocName = element.allegato
                    }
                }
                // Document download trigger.
                downloadDocument(state.privacyDoc,state.privacyDocName)
            })
            .addCase(downloadPrivacyDocById.pending, (state, action) => {
                state.isFetchingDoc = true
                // Update the state with doc id (id_box_privacy) which trigger the download.
                state.privacyDocSenderId = action.meta.arg
            })
            .addCase(downloadPrivacyDocById.rejected, (state, action) => {
                state.isFetchingDoc = false
                state.isErrorDoc = true
                state.errorMsgDoc = action.payload
            })
            /*******************************************************************
             *
             * @dev showPrivacyDocById.
             *
             */
            .addCase(showPrivacyDocById.fulfilled, (state, action) => {
                //state.isFetchingDoc = false;
                //state.isSuccessDoc = true;

                // get the base64 string.
                state.privacyDoc = action.payload.data.gmapi.response.data
                // Get the file name.
                //state.privacyDocName = action.meta.arg

                showDocument(state.privacyDoc)
            })
            .addCase(showPrivacyDocById.pending, (state, action) => {
                //state.isFetchingDoc = true;
                //state.privacyDocSenderId = action.meta.arg;
            })
            .addCase(showPrivacyDocById.rejected, (state, action) => {
                //state.isFetchingDoc = false;
                //state.isErrorDoc = true;
                //state.errorMsgDoc = action.payload;
            })
    },
})

// Export the reducer.
export const { clearPrivacyDocSenderId } = privacySlice.actions

// Export the Slice by name.
export const privacySelector = (state) => state.privacy
