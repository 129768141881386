// React
import { useEffect, useState, Fragment } from "react"
// Hook Form.
import { useForm } from "react-hook-form"
// Redux.
import { useDispatch, useSelector } from "react-redux"
// CorsoSlice.
import {
    corsoModalSelector,
    closeModal,
    uploadCorso,
    clearState,
} from "../../features/generale/organico/CorsoModalSlice"
// UI.
import Modal from "../../base/Modal"
import DocUploader from "../../base/DocUploader"
import Tooltip from "../../base/Tooltip"
// Icons.
import { OvalLoader, InformationCircle, Selector, Check } from "../../base/Icons"

import { Listbox, Transition } from "@headlessui/react"

import SearchSelect from '../../base/SearchSelect'
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';

const dayPlaceholder = 'giorno',
    monthPlaceholder = 'mese',
    yearPlaceholder = 'anno';

const daysOptions = (props) => {
    if (!props.numberDays) return
    const options = []
    for (let i = 1; i <= props.numberDays; i++) {
        options.push({ value: i.toString(), label: i.toString() })
    }
    // options.unshift({ value: '', label: dayPlaceholder, disabled: true })
    return options
}

const monthsOptions = (props) => {
    if (!props.numberMonths) return
    const options = []
    for (let i = 1; i <= props.numberMonths; i++) {
        options.push({ value: i.toString(), label: i.toString() })
    }
    // options.unshift({ value: '', label: monthPlaceholder, disabled: true })
    return options
}

const yearsOptions = (props) => {
    if (!props.numberYears) return

    const offset = props.offset ? -1 : 1

    const options = []
    for (let i = 0; i <= props.numberYears; i++) {
        options.push({ value: (new Date().getFullYear() + i * offset).toString(), label: (new Date().getFullYear() + i * offset).toString() })
    }
    // options.unshift({ value: '', label: yearPlaceholder, disabled: true })
    return options
}

/**
 * 
 * @dev CorsoModal UI Component.
 *
 * @function CorsoModal
 *
 * @returns JSX.Element
 *
 */

const tipologieCorso = [
    { value: "DPI8", label: "CORSO DPI 3° CATEGORIA (LAVORI IN QUOTA) - 8 ORE" },
    { value: "CARTELA4", label: "AGG.TO CARRELLI, CARR TELESCOPICI, CARRELLI IND/SEMOVENTI/TELESCOPICI ROTATIVI" },
    { value: "BLSDA3", label: "AGG.RIANIM.CARDIOPOLMONARE DEFIB. PREC. BLSD 3 OR" },
    { value: "SPACOA4", label: "CORSO SPAZI CONFINATI - AGGIORNAMENTO 4 ORE" },
    { value: "FLA3", label: "FORMAZIONE LAVORATORI AGGIORNAMENTO 3 ORE" },
    { value: "RSPP48", label: "CORSO RSPP 48 ORE RISCHIO ALTO" },
    { value: "SPACO", label: "CORSO SPAZI CONFINATI" },
    { value: "FLMG", label: "FORMAZIONE LAVORATORI MODULO GENERALE" },
    { value: "FLRA", label: "FORMAZIONE SPECIFICA RISCHIO ALTO 12 ORE" },
    { value: "FLRB", label: "FORMAZIONE SPECIFICA RISCHIO BASSO 4 ORE" },
    { value: "FLRM", label: "FORMAZIONE SPECIFICA RISCHIO MEDIO 8 ORE" },
    { value: "GAT", label: "CORSO GRU A TORRE 14 ORE" },
    { value: "CPIMUS32", label: "CORSO PONTEGGI 32 ORE" },
    { value: "CPIMUS5", label: "CORSO PONTEGGI AGGIORNAMENTO 5 ORE" },
    { value: "PRE", label: "CORSO PREPOSTO 8 ORE" },
    { value: "RLS32", label: "CORSO RLS 32 ORE" },
    { value: "RLSA4", label: "CORSO RLS AGGIORNAMENTO 4 ORE" },
    { value: "RLSA8", label: "CORSO RLS AGGIORNAMENTO 8 ORE" },
    { value: "CRPA4INT", label: "CORSO PER ADDETTO ANTINCENDIO - INTEGRAZIONE 4 ORE" },
    { value: "PS12O", label: "CORSO PRIMO SOCCORSO GRUPPO B/C 12 ORE" },
    { value: "PS16O", label: "CORSO PRIMO SOCCORSO GRUPPO A 16 ORE" },
    { value: "PSA4", label: "CORSO PRIMO SOCCORSO AGGIORNAMENTO 4 ORE" },
    { value: "PSA6", label: "CORSO PRIMO SOCCORSO AGGIORNAMENTO 6 ORE" },
    { value: "RSPP16", label: "CORSO RSPP 16 ORE RISCHIO BASSO" },
    { value: "RSPP32", label: "CORSO RSPP 32 ORE RISCHIO MEDIO" },
    { value: "ST", label: "CORSO SOLLEVATORI TELESCOPICI" },
    { value: "RSPPAG6", label: "CORSO RSPP AGGIORNAMENTO 6 ORE" },
    { value: "PME", label: "CORSO PIATTAFORME MOBILI ELEVABILI 10 ORE" },
    { value: "PREA6", label: "CORSO PREPOSTO AGGIORNAMENTO 6 ORE" },
    { value: "CARA4", label: "CORSO CARRELLO ELEVATORE INDUSTRIALE AGGIORNAMENTO" },
    { value: "CAR12", label: "CORSO CARRELLO ELEVATORE INDUSTRIALE 12 ORE" },
    { value: "CEI", label: "CORSO ESCAVATORI IDRAULICI CON MASSA >6000 KG" },
    { value: "PESPAV", label: "CORSO PES-PAV 16 ORE" },
    { value: "GATA4", label: "CORSO GRU A TORRE AGGIORNAMENTO 4 ORE" },
    { value: "RSPPAG14", label: "CORSO RSPP AGGIORNAMENTO 14 ORE" },
    { value: "RSPPMODA", label: "SPP MODULO A 28 ORE" },
    { value: "RSPPMODB", label: "SPP MODULO B 36 ORE" },
    { value: "RSPPMODC", label: "SPP MODULO C 24 ORE" },
    { value: "RSPPAG1B", label: "CORSO RSPP AGGIORNAMENTO 6 ORE" },
    { value: "RSPPAG1M", label: "CORSO RSPP AGGIORNAMENTO 6 ORE 1° MODULO" },
    { value: "RSPPAG1A", label: "CORSO RSPP AGGIORNAMENTO 6 ORE 1° MODULO" },
    { value: "RSPPAG2M", label: "CORSO RSPP AGGIORNAMENTO 10 ORE" },
    { value: "RSPPAG2A", label: "CORSO RSPP AGGIORNAMENTO 4 ORE 2° MODULO" },
    { value: "RSPPAG3A", label: "CORSO RSPP AGGIORNAMENTO 4 ORE 3° MODULO" },
    { value: "FLMG+RA", label: "FORMAZIONE LAVORATORI GENERALE+SPECIFICA 12 ORE" },
    { value: "FLMG+RB", label: "FORMAZIONE LAVORATORI GENERALE+SPECIFICA 4 ORE" },
    { value: "GSA", label: "CORSO GRU PER AUTOCARRO 12 ORE" },
    { value: "ESCATEA4", label: "CORSO MACCHINE MOVIMENTO TERRA AGGIORNAMENTO" },
    { value: "FOAPPREN", label: "FORMAZIONE APPRENDISTA 40 ORE" },
    { value: "CSA", label: "CORSO PER ADDETTI DEL SETTORE ALIMENTARE" },
    { value: "PMEA4", label: "CORSO PIATTAFORME AGGIORNAMENTO 4 ORE" },
    { value: "RSPPMCAG", label: "SPP MODULO C AGGIORNAMENTO" },
    { value: "RSPPMBAG", label: "SPP MODULO B AGGIORNAMENTO" },
    { value: "RSPP626", label: "CORSO RSPP D.Lgs. 626/94" },
    { value: "FLMG+RM", label: "FORMAZIONE LAVORATORI GENERALE+SPECIFICA 8 ORE" },
    { value: "RSPPMB60", label: "SPP MODULO B 60 ORE" },
    { value: "MMT16", label: "CORSO MACCHINE MOVIMENTO TERRA 16 ORE" },
    { value: "AMCOO", label: "CORSO PER COORDINATORI AMIANTO" },
    { value: "AMADD", label: "CORSO PER ADDETTI AMIANTO" },
    { value: "AMCOOA8", label: "CORSO PER COORDINATORI AMIANTO AGGIORNAMENTO 8 ORE" },
    { value: "FLA8", label: "FORMAZIONE LAVORATORI AGGIORNAMENTO 8 ORE" },
    { value: "CARTEL16", label: "CORSO CARRELLI IND/SEMOVENTI/TELESCOPICI ROTATIVI" },
    { value: "EI", label: "CORSO ESCAVATORI IDRAULICI 10 ORE" },
    { value: "SLOT", label: "CORSO SLOT" },
    { value: "MOTOSEGA", label: "CORSO PER UTILIZZO DI MOTOSEGA" },
    { value: "SSO", label: "CORSO PER OPERATORI ADDETTI SEGNALETICA STRADALE" },
    { value: "PARANCO", label: "CORSO PARANCO" },
    { value: "GSAA4", label: "CORSO GRU PER AUTOCARRO AGGIORNAMENTO 4 ORE" },
    { value: "PALECAR", label: "CORSO PALE CARICATRICI FRONTALI 10 ORE" },
    { value: "TRATAGR", label: "CORSO TRATTORI AGRICOLI" },
    { value: "REC", label: "CORSO REC 120 ORE" },
    { value: "FLA6", label: "FORMAZIONE LAVORATORI AGGIORNAMENTO 6 ORE" },
    { value: "SSP", label: "CORSO PER PREPOSTI ADDETTI SEGNALETICA STRADALE" },
    { value: "RSPPAG10", label: "CORSO RSPP AGGIORNAMENTO 10 ORE" },
    { value: "FLA4", label: "FORMAZIONE LAVORATORI AGGIORNAMENTO 4 ORE" },
    { value: "ESAMEVVF", label: "IDONEITA' TECNICA PER ADDETTO ANTINCENDIO" },
    { value: "AMADDA8", label: "CORSO PER ADDETTI AMIANTO AGGIORNAMENTO 8 ORE" },
    { value: "BLSD5", label: "RIANIMAZIONE CARDIOPOLMONARE DEFIB. PRECOCE BLSD" },
    { value: "CART12", label: "CORSO CARRELLI TELESCOPICI" },
    { value: "DIRIG", label: "CORSO DIRIGENTE" },
    { value: "SCALE", label: "CORSO SCALE" },
    { value: "TRB4", label: "CORSO TRABATTELLI" },
    { value: "TRATAGA4", label: "CORSO TRATTORI AGRIGOLI AGGIORNAMENTO" },
    { value: "STA4", label: "CORSO SOLLEVATORI TELESCOPICI - AGGIORNAMENTO" },
    { value: "SPACO5", label: "CORSO SPAZI CONFINATI - 5 ORE" },
    { value: "CRPGB", label: "CORSO ADDETTO CARROPONTE E GRU A BANDIERA" },
    { value: "SLOTA2", label: "CORSO SLOT AGGIORNAMENTO" },
    { value: "CSA5", label: "CORSO ADDETTI SETTORE ALIMENTARE - QUINQUENNALE" },
    { value: "CARTRA4", label: "CARR/SOLL/ELEVATORI SEM. TELESC. ROTATIVI AGG." },
    { value: "SSPA6", label: "CORSO SEGNALETICA STRADALE PREPOSTO - AGG. 6 ORE" },
    { value: "SSOA6", label: "CORSO SEGNALETICA STRADALE OPERATORE - AGG. 6 ORE" },
    { value: "CARROP", label: "CORSO CARROPONTE 4 ORE" },
    { value: "TACHIGRA", label: "CORSO SUL BUON FUNZIONAMENTO DEL TACHIGRAFO" },
    { value: "DIRIGA6", label: "CORSO DIRIGENTI AGGIORNAMENTO" },
    { value: "GM14", label: "FORMAZIONE GRU MOBILI 14 ORE" },
    { value: "FLI8", label: "CORSO DI FORMAZIONE DEI LAVORATORI - MODULO SPECIFICO - INTEGRAZIONE" },
    { value: "PESPAVA3", label: "LAVORI ELETTRICI PER ADDETTI PES-PAV - AGGIORNAMENTO" },
    { value: "ESCATE", label: "CORSO ESCAVATORI IDRAULICI, CARICATORI FRONTALI E TERNE 16 ORE" },
    { value: "PME8", label: "CORSO PIATTAFORME MOBILI ELEVABILI 8 ORE" },
    { value: "GMA4", label: "FORMAZIONE GRU MOBILI - AGGIORNAMENTO" },
    { value: "PCAL", label: "FORMAZIONE POMPE PER CALCESTRUZZO" },
    { value: "PCALA4", label: "FORMAZIONE POMPE PER CALCESTRUZZO - AGGIORNAMENTO" },
    { value: "CEIA4", label: "CORSO ESCAVATORI IDRAULICI CON MASSA >6000 KG - AGGIORNAMENTO" },
    { value: "DPI3CAT", label: "CORSO DPI 3° CATEGORIA" },
    { value: "FLA2", label: "FORMAZIONE LAVORATORI AGGIORNAMENTO 2 ORE" },
    { value: "PESPAVPEI", label: "CORSO PES-PAV-PEI 16 ORE" },
    { value: "RULLO", label: "CORSO DI FORMAZIONE PER LAVORATORI ADDETTI ALLA CONDUZIONE DI FINITRICI, RULLO COMPRESSORE E FRESA PER ASFALTI" },
    { value: "AI", label: "CORSO DI FORMAZIONE PER OPERATORE ANTINCENDIO - INTEGRAZIONE" },
    { value: "RSPPMODB48", label: "RSPPMODB48" },
    { value: "CARTA4", label: "CORSO CARRELLI TELESCOPICI - AGGIORNAMENTO" },
    { value: "RSPPASPPAGG", label: "CORSO PER RSPP-ASPP - AGGIORNAMENTO" },
    { value: "PSINT4", label: "CORSO PER ADDETTO PRIMO SOCCORSO GRUPPO A - INTEGRAZIONE DELLA DURATA DI 4 ORE" },
    { value: "DPIA4", label: "CORSO DPI 3° CATEGORIA (LAVORI IN QUOTA) - AGGIORNAMENTO 4 ORE" },
    { value: "ARBT1", label: "CORSO ANTINCENDIO DI TIPO 1" },
    { value: "ARMT2", label: "CORSO ANTINCENDIO DI TIPO 2" },
    { value: "ARAT3", label: "CORSO ANTINCENDIO DI TIPO 3" },
    { value: "AA2T1", label: "CORSO DI AGGIORNAMENTO ANTINCENDIO DI TIPO 1" },
    { value: "AA5T2", label: "CORSO DI AGGIORNAMENTO ANTINCENDIO DI TIPO 2" },
    { value: "AA8T3", label: "CORSO DI AGGIORNAMENTO ANTINCENDIO DI TIPO 3" },
    { value: "PESPAVA4", label: "LAVORI ELETTRICI PER ADDETTI PES-PAV - AGGIORNAMENTO" },
    { value: "DIISOC", label: "CORSO DI FORMAZIONE PER DIISOCIANATI" },
    { value: "DPI3CATAGG", label: "CORSO DPI 3° CATEGORIA - AGGIORNAMENTO" },
]

export default function CorsoModal(props) {
    const [selected, setSelected] = useState(tipologieCorso[0])

    const dispatch = useDispatch()

    // Boolean State to know if the form has Error.
    //const [formhasErrors,setFormhasErrors] = useState(false);

    const currentDate = new Date()

    /**
     *
     *  Initialize a React hook form State using UseForm Hook.
     *
     * @see https://react-hook-form.com/get-started
     *
     */
    const {
        register,
        handleSubmit,
        //setError,
        //clearErrors,
        formState,
        setValue,
        //formState: { errors } // subscription.
    } = useForm({
        mode: "onSubmit",
        reValidateMode: "onChange",
        defaultValues: {
            corsoOrganicoId: props.id || '0',
            corsoDay: "",
            corsoMonth: "",
            corsoYear: "",
            // scadenzaCorsoDay: "",
            // scadenzaCorsoMonth: "",
            // scadenzaCorsoYear: "",
            titoloCorso: "",
            tipoCorso: "",
            allegato: "",
            formErrors: "",
        },
    }) // UseForm Hook.

    const { isDirty, isValid, isSubmitted } = formState // Get the form state.

    // corsoModalSlice State.
    const { modalIsFetching, modalIsSuccess, modalIsError, modalErrorMsg } =
        useSelector(corsoModalSelector)

    /**
     *
     *  onSubmit Form handler.
     *  Post data to insert a new document.
     */
    // FIXME: Procedure incomplete.
    const onSubmit = (data) => {
        const corsoToUpload = {
            organicoId: data.corsoOrganicoId,
            data: `${data.corsoYear}-${data.corsoMonth}-${data.corsoDay}`,
            // scadenza: `${data.scadenzaCorsoYear}-${data.scadenzaCorsoMonth}-${data.scadenzaCorsoDay}`,
            // titoloCorso: data.titoloCorso,
            // titoloCorso: selected.value,
            tipologiaCorso: data.tipoCorso, //selected.value,
            // tipoCorso: data.tipoCorso,
            allegato: data.allegato,
        }
        if (isValid) {
            dispatch(uploadCorso(corsoToUpload))
        }
    }

    /**
     *
     * Component side effects.
     *
     */
    useEffect(() => {
        if (modalIsSuccess) {
            dispatch(closeModal()) // Close Modal.

            dispatch(clearState()) // Clean up the UserSlice State.
        }
    }, [dispatch, modalIsFetching, modalIsSuccess, modalIsError, modalErrorMsg, formState])

    const days = daysOptions({ numberDays: 31 })
    const months = monthsOptions({ numberMonths: 12 })
    const [years, setYears] = useState(yearsOptions({ numberYears: 100, offset: true }));

    const corsoDay = register('corsoDay', { required: true })
    const corsoMonth = register('corsoMonth', { required: true })
    const corsoYear = register('corsoYear', { required: true })
    const tipoCorso = register('tipoCorso', { required: true })


    /**
     *
     * Component render.
     *
     */
    return (
        <Modal title="Nuovo Corso">
            <form action="" className="p-4" onSubmit={handleSubmit(onSubmit)} method="POST" encType="multipart/form-data">
                <input type="hidden" {...register("corsoOrganicoId", {
                    //required: true,
                })} />
                <div className="flex gap-3">
                    <div
                        className="
                            flex flex-row 
                            text-left 
                            items-center justify-center 
                            mt-6 mb-2"
                    >
                        <label
                            htmlFor="corsoDay"
                            className="
                                mb-2 
                                pr-2 
                                pt-1 
                                text-left text-base text-afm-box-dark-gray"
                        >
                            Data Corso<top className="text-red-500 ml-1">*</top>
                        </label>

                        <span
                            className="
                                h-6 w-6 
                                rounded-full
                                bg-afm-box-light-gray
                                text-afm-box-dark-gray"
                        >
                            <Tooltip
                                message={"Data di svolgimento del corso di formazione (qualora si fosse sviluppato su diverse giornate, indicare esclusivamente la data di fine corso)."}
                                twBackground="bg-afm-box-blue"
                                translateTooltip={true}
                            >
                                <InformationCircle />
                            </Tooltip>
                        </span>
                    </div>
                </div>

                <div className="inline-flex space-x-4 w-full">
                    <Select
                        {...register("corsoDay")}
                        options={days || []}
                        onChange={(selected) => {
                            setValue("corsoDay", selected?.value || '', { shouldDirty: true, shouldValidate: true });
                        }}
                        placeholder={dayPlaceholder}
                        className="relative w-full"
                        name="corsoDay"
                    />
                    <Select
                        {...register("corsoMonth")}
                        options={months || []}
                        onChange={(selected) => {
                            setValue("corsoMonth", selected?.value || '', { shouldDirty: true, shouldValidate: true });
                        }}
                        placeholder={monthPlaceholder}
                        className="relative w-full"
                        name="corsoMonth"
                    />
                    <CreatableSelect
                        {...register("corsoYear")}
                        options={years || []}
                        formatCreateLabel={(inputValue) => inputValue}
                        onChange={(selected) => {
                            setValue("corsoYear", selected?.value || '', { shouldDirty: true, shouldValidate: true });
                            if(!selected?.value){
                                return
                            };
                            const newOption = {
                                value: selected?.value,
                                label: selected?.label
                            };
                            if (years.findIndex(x => x.value == selected.value) == -1) {
                                setYears([...years, newOption]);
                            }
                        }}
                        placeholder={yearPlaceholder}
                        className="relative w-full"
                        name="corsoYear"
                    />
                    {/* <SearchSelect
                        inputClasses="w-full 
                        px-3 
                        py-2 
                        bg-afm-box-light-gray 
                        text-afm-box-dark-gray 
                        placeholder-afm-box-dark-gray/[.50] 
                        border border-gray-300 rounded-md 
                        focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300"
                        options={days}
                        defaultValue={currentDate.getDate()}
                        register={corsoDay}
                        name="corsoDay"
                    /> */}

                    {/* <SearchSelect
                        inputClasses="w-full 
                        px-3 
                        py-2 
                        bg-afm-box-light-gray 
                        text-afm-box-dark-gray 
                        placeholder-afm-box-dark-gray/[.50] 
                        border border-gray-300 rounded-md 
                        focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300"
                        options={months}
                        defaultValue={currentDate.getMonth()}
                        register={corsoMonth}
                        name="corsoMonth"
                    />

                    <SearchSelect
                        inputClasses="w-full 
                        px-3 
                        py-2 
                        bg-afm-box-light-gray 
                        text-afm-box-dark-gray 
                        placeholder-afm-box-dark-gray/[.50] 
                        border border-gray-300 rounded-md 
                        focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300"
                        options={years}
                        defaultValue={currentDate.getFullYear()}
                        register={corsoYear}
                        name="corsoYear"
                    /> */}
                </div>

                <div className="block mt-6 mb-2">
                    <div className="flex-col text-left mt-6 mb-2">
                        <label htmlFor="tipologia">Tipologia<top className="text-red-500 ml-1">*</top></label>
                    </div>

                    {/* <SearchSelect
                        inputClasses="w-full 
                        px-3 
                        py-2 
                        bg-afm-box-light-gray 
                        text-afm-box-dark-gray 
                        placeholder-afm-box-dark-gray/[.50] 
                        border border-gray-300 rounded-md 
                        focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300"
                        options={tipologieCorso}
                        register={tipoCorso}
                        name="tipoCorso"
                    /> */}
                    <Select
                        {...register("tipoCorso")}
                        options={tipologieCorso || []}
                        onChange={(selected) => {
                            setValue("tipoCorso", selected?.value || '', { shouldDirty: true, shouldValidate: true });
                        }}
                        placeholder={'Seleziona tipologia'}
                        className="relative w-full"
                        name="tipoCorso"
                    />
                </div>

                <div className="flex-col text-left mt-6 mb-0">
                    <label>Carica Corso<top className="text-red-500 ml-1">*</top></label>
                </div>
                <div className="block">
                    <DocUploader name="allegato"
                        onChange={(file) => {
                            setValue('allegato', file);
                        }}
                    />
                </div>

                <div className="flex mt-10">
                    <div className="flex-1">
                        <button
                            type="button"
                            className="
                                flex 
                                gap-1 
                                w-auto 
                                px-7 
                                h-10 
                                items-center justify-center
                                uppercase whitespace-nowrap font-semibold 
                                text-white 
                                bg-afm-box-dark-gray
                                hover:opacity-80 
                                focus:outline-none"
                            onClick={() => {
                                dispatch(closeModal())
                            }}
                        >
                            annulla
                        </button>
                    </div>
                    <button
                        type="submit"
                        value="Submit"
                        disabled={!isDirty}
                        className="
                            flex 
                            w-auto 
                            px-7 
                            h-10 
                            gap-1 
                            items-center justify-center 
                            uppercase whitespace-nowrap font-semibold 
                            text-white 
                            bg-afm-box-blue 
                            hover:opacity-80 
                            focus:outline-none"
                    >
                        {modalIsFetching && <OvalLoader />} procedi
                    </button>
                </div>

                {modalIsError && (
                    <div
                        className="
                            h-8 
                            inline-flex 
                            items-center justify-items-center 
                            mt-2 
                            px-20 
                            bg-afm-box-error-red/10 
                            text-afm-box-error-red 
                            border border-afm-box-error-red rounded-md"
                    >
                        <p>{modalErrorMsg}</p>
                    </div>
                )}
            </form>
        </Modal>
    )
}
